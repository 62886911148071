<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData"
            >Refresh
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <!--v-text-field
      v-model="filter"
      append-icon="mdi-magnify"
      label="Filter"
      single-line
      hide-details
    ></v-text-field-->

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      :server-items-length="totalItems"
      :options.sync="options"
      dense
      multi-sort
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 100],
      }"
    >
      <template v-slot:item.status="{ item }">
        {{ item.status }}
        <v-icon :title="item.status">
          {{ renderStatus(item.status) }}
        </v-icon>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
      </template>

      <template v-slot:item.updatedAt="{ item }">
        {{ $moment(item.updatedAt).format("YYYY-MM-DD HH:mm:ss") }}
      </template>

      <template v-slot:top>
        <v-dialog v-model="dialogEdit" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">
                {{ $t("input-information") }}
                /
                {{ $t("request.approve") }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("user-id") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ editedItem.userId }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("email-address") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ editedItem.email }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("phone-number") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ editedItem.phoneNumber }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("created-datetime") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              $moment(editedItem.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("updated-datetime") }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              $moment(editedItem.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>

              <v-divider></v-divider>

              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.identifier"
                      :label="$t('corporate-identifier')"
                      maxlength="12"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nameKo"
                      :label="$t('corporate-name-ko')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nameEn"
                      :label="$t('corporate-name-en')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.ownerName"
                      :label="$t('owner-name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="startDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="formattedStartDate"
                          :label="$t('corporate-startdate')"
                          readonly
                          placeholder="YYYY-MM-DD"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formattedStartDate"
                        @input="startDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.address"
                      :label="$t('address')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.phoneNumber"
                      :label="$t('phone-number')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.categoryType"
                      :label="$t('corporate-category-type')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.categoryItem"
                      :label="$t('corporate-category-item')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="editedItem.imageUri">
                  <v-subheader>
                    {{ $t("business-registration-document") }}
                  </v-subheader>
                  <v-col>
                    <v-chip :href="imageInfo.url" target="_blank">
                      {{ imageInfo.originalFilename }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      v-model="file"
                      color="info accent-4"
                      :label="$t('business-registration-document')"
                      placeholder="Select your file"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :display-size="1000"
                      @change="uploadFile(editedItem.userId)"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip color="info accent-4" dark label small>
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-container>

              <v-divider></v-divider>

              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.remark"
                      :label="$t('remark')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" @click="dialogEdit = false"
                >{{ $t("close") }}
              </v-btn>
              <v-btn color="success darken-1" @click="submitApprove()"
                >{{ $t("request.approve") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogHold" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("request.second-request") }}</span>
            </v-card-title>
            <v-card-text>
              <v-divider></v-divider>

              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="secondRequestItem.reason"
                      :label="$t('reason-for-supplement')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="secondRequestItem.remark"
                      :label="$t('remark')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" @click="dialogHold = false"
                >{{ $t("close") }}
              </v-btn>
              <v-btn color="success darken-1" @click="submitHold()"
                >{{ $t("request.second-request") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.editButtons="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil</v-icon>
      </template>

      <template v-slot:item.holdButtons="{ item }">
        <v-icon class="mr-2" @click="holdItem(item)"> mdi-reload-alert</v-icon>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialogEdit: false,
    dialogHold: false,
    startDate: false,

    loading: true,
    options: {},
    itemsPerPage: 10,
    totalItems: 0,
    items: [],
    file: [],

    // filter: "",
    editedItem: {},
    secondRequestItem: {},
    formattedStartDate: "",
    imageInfo: {},

    snackbar: false,
    notificationMessage: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("id"),
          value: "id",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("user-id"),
          value: "userId",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("email"),
          value: "user.email",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("phone-number"),
          value: "user.phoneNumberEnc",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("status"),
          value: "status",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("corporate-identifier"),
          value: "identifier",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("corporate-name-ko"),
          value: "nameKo",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("created-datetime"),
          value: "createdAt",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("updated-datetime"),
          value: "updatedAt",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("request.approve"),
          value: "editButtons",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("request.second-request"),
          value: "holdButtons", // refresh icon
          filterable: false,
          sortable: false,
        },
      ];
    },

    renderStatus() {
      return (status) => {
        // console.log("in renderStatus:", status);
        switch (status) {
          case 1:
            return this.$t("request.second-request"); // "보완요청"
          case 2:
            return this.$t("request.approve");
          case 3:
            return this.$t("request.release");
          default:
            return this.$t("request.request"); // 0
        }
      };
    },
  },
  watch: {
    options: {
      handler() {
        console.log("in handler,", { options: this.options });
        this.refreshData();
      },
      deep: true,
    },
  },
  methods: {
    setFormattedStartDate(startDate) {
      this.formattedStartDate = this.$moment(startDate).format("YYYY-MM-DD");
    },
    async refreshData() {
      try {
        // this.$getMsal();
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage: limits } = this.options;
        console.log("in refreshData,", {
          msal: this.$msal,
          sortBy,
          sortDesc,
          page,
          limits,
        });
        const res = await this.authGet(
          `/api/self-employed-user?limits=${limits}&page=${page}`
        );
        this.items = res.data.data;
        this.totalItems = Number(res.data.totalItems);
        console.log("in refreshData,", { totalItems: this.totalItems });
        this.loading = false;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    async loadData(itemId) {
      try {
        console.log("ROUTE edit", itemId);
        const res = await this.authGet(`/api/self-employed-user/${itemId}`);
        console.log(res.data.selfEmployedUser);
        this.editedItem = res.data.selfEmployedUser;
        this.getImageInfo(this.editedItem.imageUri);
        this.setFormattedStartDate(this.editedItem.startDate);
      } catch (e) {
        const { response } = e;
        if (response) {
          console.log(response.data);
        } else {
          console.log(e);
        }
      }
    },

    async getImageInfo(imageUri) {
      if (!imageUri) {
        return false;
      }
      try {
        const encodedImageUri = encodeURIComponent(imageUri);
        console.log("for original filename", encodedImageUri);
        const res = await this.authGet(
          `/api/self-employed-user/image-info?key=${encodedImageUri}`
        );
        if (res.data.url && res.data.originalFilename !== "") {
          this.imageInfo = res.data;
        } else {
          this.editedItem.imageUri = null;
        }
        console.log("imageInfo:", this.imageInfo);
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    async editItem(item) {
      console.log("EDIT ITEM", item.id);
      this.file = [];
      this.editedItem = [];
      this.dialogEdit = true;
      await this.loadData(item.id);
      console.log("LOADED", this.editedItem);
    },

    async submitApprove() {
      this.dialogEdit = false;
      console.log("ROUTE update", this.editedItem.id);
      try {
        // const formData = this.editedItem;
        const formData = {
          status: 2,
          identifier: this.editedItem.identifier,
          nameKo: this.editedItem.nameKo,
          nameEn: this.editedItem.nameEn,
          ownerName: this.editedItem.ownerName,
          startDate: this.formattedStartDate,
          address: this.editedItem.address,
          phoneNumber: this.editedItem.phoneNumber,
          categoryType: this.editedItem.categoryType,
          categoryItem: this.editedItem.categoryItem,
          remark: this.editedItem.remark,
          imageUri: this.editedItem.imageUri,
        };

        console.log("before approve,", typeof formData, formData);
        const res = await this.authPut(
          `/api/self-employed-user/${this.editedItem.id}/approve`,
          formData
        );
        console.log("after approve,", res);
        if (res.data.result) {
          this.refreshData();
          this.showNotification("Approve Updated");
        }
      } catch (e) {
        console.error(e);
      }
    },

    holdItem(item) {
      console.log("HOLD ITEM", item.id);
      this.dialogHold = true;
      this.secondRequestItem.id = item.id;
    },

    async submitHold() {
      this.dialogHold = false;
      console.log("ROUTE hold", this.secondRequestItem.id);
      try {
        const formData = {
          status: 1,
          reason: this.secondRequestItem.reason,
          remark: this.secondRequestItem.remark,
        };

        console.log("before hold,", typeof formData, formData);
        const res = await this.authPut(
          `/api/self-employed-user/${this.secondRequestItem.id}/hold`,
          formData
        );
        console.log("after hold,", res);
        if (res.data.result) {
          this.refreshData();
          this.showNotification("Hold Updated");
        }
      } catch (e) {
        console.error(e);
      }
    },

    async uploadFile(userId) {
      console.log("UPLOAD FILE", userId, this.file);
      try {
        const formData = new FormData();

        if (this.file) {
          console.log(typeof this.file, this.file, this.file.name);
          formData.append(`file`, this.file, this.file.name);

          // console.log("before upload,", typeof formData, formData.getAll("file"));
          const res = await this.authPostWithFiles(
            `/api/self-employed-user/upload/${userId}`,
            formData
          );

          console.log("after upload,", res.data);
          if (res.data.result) {
            this.editedItem.imageUri = res.data.data;
            console.log("true, ", { editedItem: this.editedItem });
            // this.showNotification("Uploaded");
          }
        } else {
          delete this.editedItem.imageUri;
        }
      } catch (e) {
        console.error(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>
